import React from 'react'
import { Link } from 'react-router-dom'
import { Label } from '../Label'
import Metrics from './Metrics/Metrics'
import { Topic as TopicProps } from '../../constants/types'
import { getDifferenceBetweenDateAndTodayText, ucFirst } from '../../utils'

const Topic: React.FC<TopicProps> = ({ 
id, login, date, time, title, img, 
cost_label, center_label, underground_label,
views, comments, label_name, label_color }) => {
    return (
        <Link to={`/publications/${id}`} className="inherit none-decor">
            <article className="topic">
                <div className="topic__user">
                    <img src={`${process.env.REACT_APP_BASE_URL}/${img}`} alt={ucFirst(login)}/>
                    
                    <div className="topic__user-info">
                        <span className="topic__time">
                            {ucFirst(login)} <span className="topic__time-published" title={`${date} в ${time}`}>— {getDifferenceBetweenDateAndTodayText(date, time)}</span>
                        </span>
                        
                        <Label color={label_color} name={label_name}/>
                        
                        <Metrics views={views || 0} comments={comments || 0}/>
                    </div>
                </div>  
                <div className="topic__titles">
                    <div className="topic__title">{title}</div>
                </div>
                <div className="topic__mini-labels">
                    {cost_label && (
                        <Label
                            color={cost_label.background_color}
                            textColor={cost_label.color}
                            name="Цена"
                            mini
                        />  
                    )}

                    {center_label && (
                        <Label
                            color={center_label.background_color}
                            textColor={center_label.color}
                            name="Близость к центру"
                            mini
                        />
                    )}

                    {underground_label && (
                        <Label
                            color={underground_label.background_color}
                            textColor={underground_label.color}
                            name="Доступность метро"
                            mini
                        />
                    )}
                </div>
            </article>
        </Link>
    )
}

export default Topic