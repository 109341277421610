import { useEffect, useState } from 'react'

const isEndOfScreen = () => window.innerHeight + document.documentElement.scrollTop + 50 > document.documentElement.scrollHeight

export default function (more: boolean, callback: () => void) {
    const [isLoading, setIsLoading] = useState(false)

    const onScrollHandler = async () => {
        if (!more || !isEndOfScreen() || isLoading) {
            return
        }

        setIsLoading(true)

        await callback()

        setIsLoading(false)
    }

    useEffect(() => {
        window.addEventListener('scroll', onScrollHandler)

        return () => {
            window.removeEventListener('scroll', onScrollHandler)
        }
        
    }, [isLoading, more, callback])
}
