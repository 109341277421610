export enum Types {
    FETCH_TOPICS_START = "FETCH_TOPICS_START",
    FETCH_TOPICS_SUCCESS = "FETCH_TOPICS_SUCCESS",
    FETCH_TOPICS_FAIL = "FETCH_TOPICS_FAIL",
    
    RESET_FILTERS = "RESET_FILTERS",

    FILTER_TOPICS_LABEL = "FILTER_TOPICS_LABEL",
    FILTER_TOPICS_UNDERGROUND = "FILTER_TOPICS_UNDERGROUND",
    
    FILTER_TOPICS_TITLE = 'FILTER_TOPICS_TITLE',
    FILTER_TOPICS_MIN_PRICE = 'FILTER_TOPICS_MIN_PRICE',
    FILTER_TOPICS_MAX_PRICE = 'FILTER_TOPICS_MAX_PRICE',
    FILTER_TOPICS_ROOMS = 'FILTER_TOPICS_ROOMS',
    
    SORT_TOPICS = "SORT_TOPICS",  
    
    SET_FILTER_MODE = "SET_FILTER_MODE",
    CLEAR_TOPICS = "CLEAR_TOPICS",
    
    LOGIN_START = "LOGIN_START",
    LOGIN_SUCCESS = "LOGIN_SUCCESS",
    LOGIN_FAILURE = "LOGIN_FAILURE",

    REGISTER_FAILURE = "REGISTER_FAILURE",
    
    LABEL_START = "LABEL_START",
    LABEL_SUCCESS = "LABEL_SUCCESS",
    LABEL_FAIL = "LABEL_FAIL",

    FETCH_NEWS_START = "FETCH_NEWS_START",
    FETCH_NEWS_SUCCESS = "FETCH_NEWS_SUCCESS",
    FETCH_NEWS_FAIL = "FETCH_NEWS_FAIL",

    UNDERGROUND_START = "UNDERGROUND_START",
    UNDERGROUND_SUCCESS = "UNDERGROUND_SUCCESS",
    UNDERGROUND_FAIL = "UNDERGROUND_FAIL",

    TYPE_FLATS_START = "TYPE_FLATS_START",
    TYPE_FLATS_SUCCESS = "TYPE_FLATS_SUCCESS",
    TYPE_FLATS_FAIL = "TYPE_FLATS_FAIL",

    ON_EXIT = "ON_EXIT"    
}

export type Response = {
    message: string
}

export type LoginRequest = {
    login: string
    password: string
}

export type LoginResponse = {
    message: string
    token?: string
    user?: {
        login: string
        surname?: string
        user_name?: string
        role_name?: string
        role_id?: number
        img?: string | null
    }
}

export type RegisterResponse = {
    message: string
    token?: string
    user?: {
        login: string
        surname?: string
        user_name?: string
        role_name?: string
        role_id?: number
        img?: string | null
    }
}

export type RegisterRequest = {
    login: string
    password: string
    surname: string
    name: string
    image: string | null
}

type Label = {
    color: string
    background_color: string
}


export type Topic = {
    id: number
    login: string
    img: string
    title: string
    date: string
    time: string
    text: string
    views: number | null
    comments: number | null
    label_id: number
    label_name: string
    label_color: string
    cost_label?: Label
    center_label?: Label
    underground_label?: Label
}

export type Comment = {
    id: number
    id_topic: number
    id_parent: number | null
    date: string
    time: string
    login: string
    text: string
    img: string
    comments: Array<Comment> | null
}

export type Image = {
    id: number
    name: string
    id_topic: number
    date: string
    time: string
}

export type Underground = {
    underground_id: string
    underground_name: string
    distance: number
    branch_id: string
    branch_name: string
    branch_color: string
}

export type SelectUnderground = {
    underground_id: number
    underground_name: string
    branch_name: string
    branch_color: string
}

export type TypeFlats = {
    id_type: string
    name: string
}

export type Rating = {
    flat: string
    ecology: string
    infrastructure: string
    position: string
}

export type UserRating = {
    id_rating: number
    flat_rating: number
    ecology_rating: number
    infrastructure_rating: number
    position_rating: number
    id_topic: number
    login: string
}

export type NewsComment = {
    "id": string,
    "id_news": string,
    "login": string,
    "text": string,
    "date": string,
    "time": string,
    "img": string,
}

export type News = {
    id_news: number
    title: string
    text: string
    comments: Array<NewsComment>
}

export type TopicItem = {
    id: number
    title: string
    login: string
    img: string
    date: string
    time: string
    text: string
    label_id: number
    label_name: string
    label_color: string
    address: string
    cost: number
    floor: number
    floors_in_house: number
    room_count: number
    square: number
    type_flat_name: string,
    geo_lat: string
    geo_lon: string
    views: number
    count: number
    link: string
    center_distance: number
    difference_percent: number
    ratings: Rating
    userRating: UserRating
    undergrounds: Array<Underground>
    comments: Array<Comment>
    images: Array<Image>
}