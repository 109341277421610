import React, { useState } from 'react'
import clsx from 'clsx'

type Props = {
    images: Array<string>,
    selectedDefault: number
}

const Slider: React.FC<Props> = ({ images, selectedDefault }) => {
    const [selected, setSelected] = useState(selectedDefault)
    
    const onChangeSelected = (increment: boolean) => {
        if (increment) {
            if (selected + 1 < images.length) {
                setSelected((prevState) => prevState + 1)
            } else setSelected(0)
        } else {
            if (selected !== 0) {
                setSelected((prevState) => prevState - 1)
            } else setSelected(images.length - 1)
        }
    }
    
    return (
        <div className="slider">
            <div className="slider__items">
                <div className="slider__arrow slider__arrow-left" onClick={ () => onChangeSelected(false) }>
                    <svg width="20" className="slider__svg" height="32" viewBox="0 0 16 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.757643 14.7684L13.7085 27.6834C14.1328 28.1063 14.8198 28.1056 15.2434 27.6812C15.6667 27.2569 15.6656 26.5695 15.2412 26.1463L3.06123 14L15.2416 1.85369C15.6659 1.43041 15.667 0.743483 15.2438 0.319109C15.0315 0.106375 14.7533 7.55882e-06 14.4751 7.5345e-06C14.1976 7.51024e-06 13.9205 0.105663 13.7085 0.316921L0.757643 13.2316C0.553276 13.4349 0.438596 13.7116 0.438596 14C0.438596 14.2883 0.553604 14.5647 0.757643 14.7684Z" fill="#AEAEAE"/>
                    </svg>
                </div>
                <div className="slider__arrow slider__arrow-right" onClick={ () => onChangeSelected(true) }>
                    <svg width="20" className="slider__svg" height="32" viewBox="0 0 16 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15.2424 13.2316L2.29153 0.316633C1.86722 -0.106263 1.18023 -0.105552 0.756627 0.318821C0.333347 0.74314 0.334441 1.4305 0.758815 1.85373L12.9388 14L0.758377 26.1463C0.334058 26.5696 0.332964 27.2565 0.756189 27.6809C0.96854 27.8936 1.24673 28 1.52493 28C1.80241 28 2.07951 27.8943 2.29148 27.6831L15.2424 14.7684C15.4467 14.5651 15.5614 14.2884 15.5614 14C15.5614 13.7117 15.4464 13.4353 15.2424 13.2316Z" fill="#AEAEAE"/>
                    </svg>
                </div>
                {
                    images.map((i, idx) => (
                        <div key={idx} className={`slider__item ${idx === selected ? 'slider__item-selected' : '' }`}>
                            <img src={process.env.REACT_APP_BASE_URL + i} alt={i}/>
                        </div>
                    ))
                }
            </div>
            
            <div className="slider__preview">
                {
                    images.map((i, idx) => (
                        <div key={idx} className={clsx(selected === idx && 'slider__preview-selected')}> 
                            <img src={process.env.REACT_APP_BASE_URL + i} alt={i} onClick={ () => setSelected(idx) }/>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default Slider