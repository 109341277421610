import { Types } from '../constants/types'

export type Label = {
    id: number,
    name: string,
    color: string,
} 

export type LabelType = {
    loading: boolean,
    error: boolean,
    labels: Array<Label>,
}

const initialState: LabelType = {
    loading: false,
    error: false,
    labels: [],
}

const LabelReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case Types.LABEL_START:
            return {
                ...state,
                loading: true,
            }
        case Types.LABEL_SUCCESS: 
            return {
                ...state,
                loading: false,
                labels: action.payload,
            }
        case Types.LABEL_FAIL:
            return {
                ...state,
                loading: false,
                error: true,
            }
        default:
            return state
    }
}

export default LabelReducer