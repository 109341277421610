import axios from 'axios'
import { Dispatch } from 'redux'
import { Types } from '../constants/types'
import { Label } from '../reducers/LabelReducer'
import { LABELS_URL } from '../constants/API'

export const fetchLabelsStart = () => {
    return {
        type: Types.LABEL_START
    }
}

export const fetchLabelsSuccess = (labels: Array<Label>) => {
    return {
        type: Types.LABEL_SUCCESS,
        payload: labels,
    }
}

export const fetchLabelsFail = () => {
    return {
        type: Types.LABEL_FAIL,
    }
}

export const fetchLabels = async (dispatch: Dispatch) => {
    dispatch(fetchLabelsStart())
    
    await axios.get<Array<Label>>(LABELS_URL)
        .then((response) => dispatch(fetchLabelsSuccess(response.data))) 
        .catch(() => dispatch(fetchLabelsFail()))
}