import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Modal } from 'react-bootstrap'
import { clearError, login, register } from '../../actions/SessionActions'
import { Dispatch } from 'redux'
import { AppState } from '../../reducers'
import { LoginRequest, RegisterRequest } from '../../constants/types'
import { getIsAuthenticated } from '../../selectors/AuthSelectors'
import { ImagePreview } from '../ImagePreview'
import axios, { AxiosResponse } from 'axios'
import { UPLOAD_IMG_URL } from '../../constants/API'
import { ResponseImages } from '../ImagePreview/ImagePreview'
import * as validations from './Auth.validate'

type Props = {
    modalShow: boolean
    onHide: () => void
    login: (data: LoginRequest) => void
    register: (data: RegisterRequest) => void
    isAuthenticated: boolean
    errorLogin: number
    errorRegister: number
    clearError: () => void
}

type ModeType = "login" | "register"

type State = {
    mode: ModeType
    surname?: string
    name?: string
    login: string
    password: string
    images: Array<string>
    validate: boolean | undefined
}

class Auth extends Component<Props, State> {
    state: State = {
        mode: 'login',
        login: '',
        password: '',
        images: [],
        validate: undefined,
    }
    
    onChangeMode = (mode: ModeType) => {
        this.setState({ mode })
        this.props.clearError()
    }
    
    onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target
        
        // @ts-ignore
        this.setState({
            [name]: value
        } as Pick<State, keyof State>)
    }
    
    onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        
        const { mode, images, login, password, name, surname } = this.state
        const { login: loginAction, register: registerAction } = this.props
        
        if (mode === 'login') {
            const loginData = { login, password }
            
            if (validations.loginSchema.isValidSync(loginData)) {
                loginAction(loginData)
                this.setState({ validate: true })
            } else {
                this.setState({ validate: false })
            }
            return
        } 
        
        const registerData = {
            login,
            password,
            surname,
            name,
            image: !!images.length ? images[0] : null,
        } 
        
        if (validations.registerSchema.isValidSync(registerData)) {
            registerAction(registerData)
            this.setState({ validate: true })
        } else {
            this.setState({ validate: false })
        }
    }
    
    onRemoveImg = (e: React.MouseEvent<HTMLSpanElement>, img: string) => {
        e.preventDefault()

        const { images } = this.state

        this.setState({ images: images.filter((i) => i !== img) })
    }
    
    onChangeFiles = (e: React.ChangeEvent<HTMLInputElement>) => {
        // @ts-ignore
        const files = Array.from(e.target.files)

        const formData = new FormData()

        files.forEach((file, i) => {
            formData.append(String(i), file)
        })

        axios({
            method: 'POST',
            url: UPLOAD_IMG_URL,
            data: formData,
            headers: { "Content-Type": "multipart/form-data" },
        }).then((res: AxiosResponse<ResponseImages>) => {
            this.setState({ images: [ ...res.data ] })
        })
    }
    
    render() {
        const { modalShow, onHide, isAuthenticated, errorLogin, errorRegister } = this.props
        const { mode, images, surname, name, login, password, validate } = this.state
        
        if (isAuthenticated) onHide()
        
        return (
            <Modal show={modalShow}
                   onHide={onHide}
                   animation={ false }
                   dialogClassName="auth"
            >
                <Modal.Title className="auth__title">
                    <span
                        style={mode === 'login' ? { textDecoration: 'underline' } : undefined}
                        onClick={ () => this.onChangeMode('login') }>   
                        Войти
                    </span>
                    &nbsp;&nbsp;|&nbsp;&nbsp;
                    <span
                        style={mode === 'register' ? { textDecoration: 'underline' } : undefined}
                        onClick={ () => this.onChangeMode('register') }>   
                    Регистрация
                    </span>
                </Modal.Title>


                <Modal.Body>

                    <form onSubmit={this.onSubmit}>
                        <div>
                            {
                                mode === 'register' && (
                                    <>
                                        <div className="field auth__field">
                                            <label className="field" htmlFor="surname">Фамилия</label>
                                            <input className="field" type="text" name="surname" autoComplete="off" onChange={this.onChangeInput} value={surname}/>
                                        </div>
                                        <div className="field auth__field">
                                            <label className="field" htmlFor="name">Имя</label>
                                            <input className="field" type="text" name="name" autoComplete="off" onChange={this.onChangeInput} value={name}/>
                                        </div>
                                    </>
                                )
                            }
                            <div className="field auth__field">
                                <label className="field" htmlFor="login">Логин</label>
                                <input className="field" type="text" name="login" autoComplete="off" onChange={this.onChangeInput} value={login}/>
                            </div>
                            <div className="field auth__field">
                                <label className="field" htmlFor="password">Пароль</label>
                                <input className="field" type="password" name="password" autoComplete="off" onChange={this.onChangeInput} value={password}/>
                            </div>
                            {
                                mode === 'register' && (
                                    <div className="field auth__field">
                                        <ImagePreview images={images} onChangeFiles={this.onChangeFiles} onRemoveImg={this.onRemoveImg}/>
                                    </div>
                                )
                            }
                            {
                                errorLogin === 404 ? (
                                    <div className="auth__error">Логин или пароль неверные!</div>
                                ) : errorLogin === 500 ? (
                                    <div className="auth__error">Сервер не ответил на запрос!</div>
                                ) : null
                            }
                            {
                                errorRegister === 404 ? (
                                    <div className="auth__error">Пользователь с таким логином уже существует!</div>
                                ) : errorRegister === 500 ? (
                                    <div className="auth__error">Сервер не ответил на запрос!</div>
                                ) : null
                            }
                            {
                                validate === false && (
                                    <div className="auth__error">Проверьте правильность заполнения данных!</div>
                                )
                            }
                        </div>

                        <div className="text-right">
                            <button className="button button-link" type="submit">
                                {
                                    mode === 'login' ? 'Войти' : 'Регистрация'
                                }
                            </button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        )
    }
}

const mapStateToProps = (state: AppState) => {
    const { user: { id, token, errorLogin, errorRegister } } = state
    
    return {
        isAuthenticated: getIsAuthenticated(id, token),
        errorLogin,
        errorRegister
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        login: (data: LoginRequest) => login(dispatch, data),
        register: (data: RegisterRequest) => register(dispatch, data),
        clearError: () => clearError(dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Auth)