import { Topic, Types } from '../constants/types'

export type TopicState = {
    loading: boolean,
    error: boolean,
    more: boolean,
    page: number,
    topicsNotFound: boolean,
    topics: Array<Topic>,
    filterMode: boolean,
    filterLabel: number | null,
    filterUnderground: number | null,
    filterTitle: string,
    filterMinPrice: string,
    filterMaxPrice: string,
    filterRooms: string,
    sortType: string,
}

const initialState: TopicState = {
    loading: false,
    error: false,
    more: true,
    page: 1,
    topicsNotFound: false,
    topics: [],
    filterMode: false,
    filterLabel: null,
    filterUnderground: null,
    filterTitle: '',
    filterMinPrice: '',
    filterMaxPrice: '',
    filterRooms: '',
    sortType: 'new',
}

const TopicReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case Types.FETCH_TOPICS_START:
            return {
                ...state,
                loading: true,
                page: action.payload,
                topics: action.payload === 1 ? [] : [...state.topics],
            }
        case Types.FETCH_TOPICS_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                page: action.payload.length > 0 ? state.page + 1 : state.page,
                more: action.payload.length > 0,
                topics: state.page > 1 ? [ ...state.topics, ...action.payload] : action.payload,
                topicsNotFound: state.page > 1 ? state.topicsNotFound : !action.payload.length
            }
        case Types.CLEAR_TOPICS:
            return {
                ...state,
                loading: false,
                error: false,
                more: true,
                filterMode: false,
                page: 1,
                topics: [],
            }
        case Types.FETCH_TOPICS_FAIL:
            return {
                ...state,
                loading: false,
                error: true
            }
        case Types.SET_FILTER_MODE:
            return {
                ...state,
                filterMode: action.payload,
            }
        case Types.FILTER_TOPICS_LABEL:
            return {
                ...state,
                filterLabel: action.payload,
            }
        case Types.FILTER_TOPICS_UNDERGROUND: 
            return {
                ...state,
                filterUnderground: action.payload,
            }
        case Types.FILTER_TOPICS_TITLE:
            return {
                ...state,
                filterTitle: action.payload,
            }
        case Types.FILTER_TOPICS_ROOMS:
            return {
                ...state,
                filterRooms: action.payload,
            }
        case Types.FILTER_TOPICS_MAX_PRICE:
            return {
                ...state,
                filterMaxPrice: action.payload,
            }
        case Types.FILTER_TOPICS_MIN_PRICE:
            return {
                ...state,
                filterMinPrice: action.payload,
            }
        case Types.SORT_TOPICS:
            return {
                ...state,
                sortType: action.payload,
            }
        case Types.RESET_FILTERS: 
            return initialState
        default:
            return state
    }
}

export default TopicReducer