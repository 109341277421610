import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import { ISideBar } from './ISideBar'

import helpIcon from '../../resources/help.svg'
import newspaper from '../../resources/newspaper.svg'
import rulesIcon from '../../resources/rules.svg'
import publishersIcon from '../../resources/publishers.svg'
import { CreateTopic } from '../CreateTopic'
import { AppState } from '../../reducers'
import { getIsAuthenticated } from '../../selectors/AuthSelectors'


const SideBarItems: Array<ISideBar> = [
    {
        name: 'Публикации',
        path: '/publications',
        icon: publishersIcon,
        forAuthUser: false
    },
    {
        name: 'Новости',
        path: '/news',
        icon: newspaper,
        forAuthUser: false
    },
    // {
    //     name: 'Правила',
    //     path: '/rules',
    //     icon: rulesIcon,
    //     forAuthUser: false
    // },
    {
        name: 'Помощь',
        path: '/help',
        icon: helpIcon,
        forAuthUser: false
    },
]

type Props = {
    isAuthenticated: boolean
}

const SideBar: React.FC<Props> = ({ isAuthenticated }) => {
    const location = useLocation()
    const [modalShow, setModalShow] = useState(false)
    
    return (
        <>
            <div className="sidebar">
                {
                    SideBarItems.map((item, index) => {
                        if (item.forAuthUser && !isAuthenticated) return null
                        
                        return (
                            <Link to={item.path} className="inherit sidebar__link" key={index}>
                                <div className={`sidebar__item ${item.path === location.pathname ? 'sidebar__item_active' : ''}`}>
                                    <span className="sidebar__img" style={{ backgroundImage: `url(${item.icon})` }}/>
                                    <div className="sidebar__text">
                                        {item.name}
                                    </div>
                                </div>
                            </Link>
                        )
                    })
                }
                
                {
                    isAuthenticated && (
                        <button className="button button-link sidebar__create" onClick={ () => setModalShow(true) }>
                            Создать тему
                        </button>       
                    )
                }
            </div>
            
            { isAuthenticated && <CreateTopic modalShow={ modalShow } onHide={ () => setModalShow(false) }/> }
        </>
    )
}

const mapStateToProps = (state: AppState) => {
    const { user: { id, token } } = state

    return {
        isAuthenticated: getIsAuthenticated(id, token)
    }
}
export default connect(mapStateToProps)(SideBar)