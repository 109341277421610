import React from 'react'
import { Link } from 'react-router-dom'

type Props = {
    id: number
    title: string
    text: string
}

const News: React.FC<Props> = ({ id, title, text }) => {
    return (
        <Link to={`/news/${id}`} className="inherit none-decor">
            <article className="topic">
                <div className="topic__titles topic__titles--news">
                    <div className="topic__title">{title}</div>
                </div>
            </article>
        </Link>
    )
}

export default News