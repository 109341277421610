import React, { Component } from 'react'

interface Props {
    onChangeFiles: (e: React.ChangeEvent<HTMLInputElement>) => void
    onRemoveImg: (e: React.MouseEvent<HTMLSpanElement>, img: string) => void
    images: Array<string>
    multiple: boolean
}

interface State {
    
}

export type ResponseImages = Array<string>

class ImagePreview extends Component<Props, State> {
    
    static defaultProps = {
        onChangeFiles: () => {},
        onRemoveImg: () => {},
        images: [],
        multiple: false,
    }

    inputFile = React.createRef<HTMLInputElement>()

    onOpenFileBrowser = () => {
        this.inputFile.current && this.inputFile.current.click()
    }

    render() {
        const { images, multiple, onChangeFiles, onRemoveImg } = this.props

        return (
            <div className="image">
                <label className="field" htmlFor="text">Изображения</label>
                <div className="image__controls">
                    <input
                        type="file"
                        ref={this.inputFile}
                        onChange={onChangeFiles}
                        multiple={multiple}
                        accept="image/*"
                        hidden
                    />
                    <button className="button button-link" type="button" onClick={this.onOpenFileBrowser}>
                        Загрузить
                    </button>
                </div>

                <div className="image__images_list">
                    {images.map((img) => (
                        <span className="image__img" key={img}>
                            <a href={process.env.REACT_APP_BASE_URL + img} target="_blank">
                                <span className="image__cancel"
                                      onClick={(e) => onRemoveImg(e, img)}>×</span>
                                <img src={process.env.REACT_APP_BASE_URL + img} alt={img}/>
                            </a>
                        </span>
                    ))}
                </div>
            </div>
        )
    }
}

export default ImagePreview