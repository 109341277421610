import React, { Component } from 'react'
import { connect } from 'react-redux'
import axios, { AxiosError } from 'axios'
import Rating from '@material-ui/lab/Rating'
import Cookies from 'js-cookie'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { YMaps, Map, Placemark } from 'react-yandex-maps'
import { COMMENT_URL, RATING_URL, TOPIC_URL } from '../constants/API'
import { TopicItem, Rating as RatingType } from '../constants/types'
import { CONTAINER_WIDTH } from '../constants/layout'
import { Underground } from '../components/Ungerground'
import { Comment } from '../components/Comment'
import { SideBar } from '../components/SideBar'
import { getDifferenceBetweenDateAndTodayText, ucFirst } from '../utils'
import { Label } from '../components/Label'
import { Slider } from '../components/Slider'
import { Response } from '../constants/types'
import { AppState } from '../reducers'
import { getIsAuthenticated } from '../selectors/AuthSelectors'
import { CreateTopic } from '../components/CreateTopic'
import { DataUnit } from '../components/DataUnit'
import { Tooltip } from '@material-ui/core'
import helpIcon from '../resources/help.svg'

interface URLParams {
    id: string
}

interface TopicProps extends RouteComponentProps<URLParams> {
    isAuthenticated: boolean
}

interface State {
    loading: boolean,
    error: string,
    topic: TopicItem | null,
    render: boolean,
    textarea: string,
    showEditModal: boolean,
    ratings: RatingType | null,

}

class TopicPage extends Component<TopicProps, State> {
    state: State = {
        loading: true,
        error: '',
        topic: null,
        render: false,
        textarea: '',
        showEditModal: false,
        ratings: null,
    }

    componentDidMount() {
        this.getTopic()
    }
    
    getTopic = async () => {
        const { match } = this.props
        const url = TOPIC_URL.replace(':id', match.params.id)

        await axios.get<TopicItem>(url, {
            headers: { 'token': Cookies.get('token') }
        })
            .then((response) => setTimeout(() => 
                this.setState({ topic: response.data, loading: false, ratings: {
                    flat: String(response.data.userRating?.flat_rating || 0),
                    ecology: String(response.data.userRating?.ecology_rating || 0),
                    infrastructure: String(response.data.userRating?.infrastructure_rating || 0),
                    position: String(response.data.userRating?.position_rating || 0),
                } }), 100))
            .catch((err: AxiosError) => {
                if (err.response?.status === 404) {
                    this.setState({ error: 'Публикация не найдена!' })   
                } else this.setState({ error: 'Произошла ошибка при получении данных!' })
            })
    }

    onSubmitComment = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()

        const { textarea } = this.state
        
        this.onSubmit(textarea, null)
    }
    
    onSubmit = async (text: string, parentId: number | null) => {
        const { match } = this.props
        const topicId = match.params.id
        const url = COMMENT_URL.replace(':id', topicId)
        
        if (!!text) {
            await axios.post<Response>(url, {
                topicId,
                parentId: parentId,
                login: Cookies.get('id'),
                text: text,
            }).then((_) => {
                this.setState({ textarea: '' })
                this.getTopic()
            })   
        }
    }
    
    onChangeTextArea = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        e.preventDefault()

        const { value } = e.target
        
        this.setState({
            textarea: value
        })
    }
    
    onChangeRating = (event: React.ChangeEvent<{}>, value: number | null) => {
        // @ts-ignore
        const { name } = event.target
        
        // @ts-ignore
        this.setState((prevState) => {
            return {
                ratings: {
                    ...prevState.ratings,
                    [name]: String(value),
                }
            }
        })
    }
    
    onSaveRating = async () => {
        const { ratings } = this.state
        const { match } = this.props
        const topicId = match.params.id
        
        const url = RATING_URL.replace(':id', topicId)
        
        await axios.post(url, {
            flat: ratings?.flat || 0,
            ecology: ratings?.ecology || 0,
            infrastructure: ratings?.infrastructure || 0,
            position: ratings?.position || 0,
        })
    }
    
    checkForHouse = (type: string) => {
          if (!type) return null
        
        const { topic } = this.state

        const cost = new Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'RUB' }).format(topic!.cost)
        
        if (type.toLowerCase() === 'квартира') {
            return `${topic!.room_count}-комн. квартира за ${cost}`
        }
        
        return `Апартаменты за ${cost}`
    }
    
    

    render() {
        const { loading, error, topic, textarea, showEditModal, ratings } = this.state
        const { isAuthenticated } = this.props
        
        const login = Cookies.get('id')
        
        return (
            <main>
                <SideBar/>
                
                {topic && (
                    <CreateTopic
                        editMode
                        editData={topic}
                        modalShow={ showEditModal }
                        onHide={() => this.setState({ showEditModal: false })}/>  
                )}
                    
                    <div className="article topic" style={{ width: `${CONTAINER_WIDTH - 265}px` }}>
                        {!!error ? <div className="article__title text-center">{error}</div> : loading || !topic ? <TopicPageLoading/> : (
                            <div>
                            <div className="topic__user">
                                <img src={`${process.env.REACT_APP_BASE_URL}/${topic.img}`} alt={ucFirst(topic.login)}/>

                                <div className="topic__user-info topic__user-info_topic">
                                    <span className="topic__time article__time">{ucFirst(topic.login)}&nbsp;<span title={`${topic.date} в ${topic.time}`}>{getDifferenceBetweenDateAndTodayText(topic.date, topic.time)} с меткой</span></span>

                                    <Label color={topic.label_color} name={topic.label_name}/>
                                </div>
                                
                                {login === topic.login && (
                                    <div className="topic__edit-btn" onClick={() => this.setState({ showEditModal: true })}>
                                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g clipPath="url(#clip0)">
                                                <path d="M0 14.2497V18.0002H3.75052L14.817 6.93363L11.0665 3.18311L0 14.2497Z" fill="white"/>
                                                <path d="M17.7078 2.62785L15.3724 0.29254C14.9824 -0.0975135 14.3473 -0.0975135 13.9572 0.29254L12.127 2.12279L15.8775 5.87331L17.7077 4.04306C18.0978 3.653 18.0978 3.0179 17.7078 2.62785Z" fill="white"/>
                                            </g>
                                            <defs>
                                                <clipPath id="clip0">
                                                    <rect width="18" height="18" fill="white"/>
                                                </clipPath>
                                            </defs>
                                        </svg>
                                    </div>  
                                )}
                            </div>
                            <div className="article__container">
                                {!!topic.undergrounds.length && (
                                    <div className="underground article__underground">
                                        {topic.undergrounds.map((u) => (
                                            <Underground
                                                key={u.underground_id}
                                                name={u.underground_name}
                                                distance={u.distance}
                                                color={u.branch_color}/>
                                        ))}
                                    </div>
                                )}

                                <div className="article__title article__address">
                                    <a href={topic.link} target="_blank" className="inherit">
                                        {this.checkForHouse(topic.type_flat_name)}                                     
                                    </a>
                                    
                                    <Tooltip
                                        className="article__tooltip-wrapper"
                                        placement="right"
                                        title={
                                            <div className="article__tooltip">
                                                {topic.difference_percent > 0
                                                    ? `Цена выше рыночной на ${topic.difference_percent}%`
                                                    : topic.difference_percent < 0
                                                        ? `Цена ниже рыночной на ${Math.abs(topic.difference_percent)}%`
                                                        : 'Нет информации касательно рыночной цены'
                                                }
                                            </div>
                                        }
                                    >
                                        <img src={helpIcon} alt="Help"/>
                                    </Tooltip>
                                </div>

                                <div className="article__title">{topic.title}</div>

                                <div className="article__text">{topic?.text}</div>

                                {!!topic.images.length && (
                                    <Slider
                                        images={topic.images.map((i) => i.name)}
                                        selectedDefault={0}
                                    />
                                )}

                                <section className="article-info">
                                    <div className="article__title">Общая информация</div>

                                    <div className="article-info__items">
                                        <DataUnit title="Тип жилья" value={topic.type_flat_name}/>
                                        <DataUnit title="Адрес" value={topic.address}/>
                                    </div>

                                    <div className="article-info__items">
                                        <DataUnit title="Общая площадь">
                                            <div>{topic.square} м<sup>2</sup></div>
                                        </DataUnit>
                                        <DataUnit title="Этаж" value={String(topic.floor)}/>
                                    </div>

                                    <div className="article-info__items">
                                        <DataUnit title="Количество комнат" value={String(topic.room_count)}/>
                                        <DataUnit title="Этажей в доме" value={String(topic.floors_in_house)}/>
                                    </div>
                                </section>
                                
                                <section className="article-info">
                                    <div className="article__title">Оценки пользователей</div>

                                    <div className="article-info__items">
                                        <DataUnit title="Квартира">
                                            <Rating
                                                disabled
                                                precision={0.5}
                                                value={+topic.ratings?.flat || 0}
                                            />    
                                        </DataUnit>
                                        <DataUnit title="Экология">
                                            <Rating
                                                disabled
                                                precision={0.5}
                                                value={+topic.ratings?.ecology || 0}
                                            />
                                        </DataUnit>
                                    </div>
                                    <div className="article-info__items">
                                        <DataUnit title="Инфраструктура">
                                            <Rating
                                                disabled
                                                precision={0.5}
                                                value={+topic.ratings?.infrastructure || 0}
                                            />
                                        </DataUnit>
                                        <DataUnit title="Расположение">
                                            <Rating
                                                disabled
                                                precision={0.5}
                                                value={+topic.ratings?.position || 0}
                                            />
                                        </DataUnit>
                                    </div>
                                </section>
                                
                                {isAuthenticated && (
                                    <section className="article-info">
                                        <div className="article__title">Моя оценка</div>

                                        <div className="article-info__items">
                                            <DataUnit title="Квартира">
                                                <Rating
                                                    name="flat"
                                                    onChange={this.onChangeRating}
                                                    value={+ratings?.flat! || 0}
                                                />
                                            </DataUnit>
                                            <DataUnit title="Экология">
                                                <Rating
                                                    name="ecology"
                                                    onChange={this.onChangeRating}
                                                    value={+ratings?.ecology! || 0}
                                                />
                                            </DataUnit>
                                        </div>
                                        <div className="article-info__items">
                                            <DataUnit title="Инфраструктура">
                                                <Rating
                                                    name="infrastructure"
                                                    onChange={this.onChangeRating}
                                                    value={+ratings?.infrastructure! || 0}
                                                />
                                            </DataUnit>
                                            <DataUnit title="Расположение">
                                                <Rating
                                                    name="position"
                                                    onChange={this.onChangeRating}
                                                    value={+ratings?.position! || 0}
                                                />
                                            </DataUnit>
                                        </div>
                                        <button
                                            className="button button-link comment__button comment__button-send"
                                            onClick={() => this.onSaveRating()}
                                        >
                                            Сохранить
                                        </button>
                                    </section>  
                                )}

                                <YMaps>
                                    <div className="article__maps">
                                        <Map width="100%" height={360} defaultState={{ center: [parseFloat(topic.geo_lat), parseFloat(topic.geo_lon)], zoom: 15 }}>
                                            <Placemark geometry={[parseFloat(topic.geo_lat), parseFloat(topic.geo_lon)]} />
                                        </Map>
                                    </div>
                                </YMaps>

                                <div className="article__separator"/>

                                <div className="article__comments">
                                    <div className="article__count">Комментарии ({topic?.count})</div>

                                    <form className="comment__form comment__form_main" onSubmit={this.onSubmitComment}>
                                        <span>Ваше сообщение</span>

                                        <div>
                                    <textarea className="field" value={textarea} onChange={this.onChangeTextArea} disabled={!isAuthenticated}>
                                    </textarea>

                                            <div className="comment__buttons">
                                                <button className="button button-link comment__button comment__button-send" type="submit" disabled={!isAuthenticated}>
                                                    Отправить
                                                </button>
                                            </div>
                                        </div>
                                    </form>

                                    {
                                        topic?.comments.map((comment) => {
                                            const childComments = comment.comments?.map((c) => (
                                                <Comment
                                                    key={c.id}
                                                    login={c.login}
                                                    text={c.text}
                                                    date={c.date}
                                                    time={c.time}
                                                    img={c.img}
                                                    parentId={c.id_parent}
                                                    isAuthenticated={isAuthenticated}
                                                    parent
                                                    onSubmit={this.onSubmit}
                                                />
                                            ))

                                            return (
                                                <>
                                                    <Comment
                                                        key={comment.id}
                                                        login={comment.login}
                                                        text={comment.text}
                                                        date={comment.date}
                                                        time={comment.time}
                                                        img={comment.img}
                                                        parentId={comment.id}
                                                        isAuthenticated={isAuthenticated}
                                                        onSubmit={this.onSubmit}
                                                    />
                                                    {childComments}
                                                </>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                        )}
                    </div>
            </main>
        )
    }
}

const mapStateToProps = (state: AppState) => {
    const { user: { id, token } } = state

    return {
        isAuthenticated: getIsAuthenticated(id, token)
    }
}

export default connect(mapStateToProps)(withRouter(TopicPage))


const TopicPageLoading: React.FC = () => {
    
    const renderDataUnit = () => {
        return (
            <div className="loading-topic__wrapper">
                <div>
                    <div className="loading-topic__information loading-topic__item--padding_margin"/>
                    <div className="loading-topic__information loading-topic__information--value loading-topic__item--padding_margin"/>
                </div>

                <div>
                    <div className="loading-topic__information loading-topic__item--padding_margin"/>
                    <div className="loading-topic__information loading-topic__information--value loading-topic__item--padding_margin"/>
                </div>
            </div>
        )
    }
    
    return (
        <div className="loading-topic">
            <div className="loading-topic__item loading-topic__item--padding">
                <div className="loading-topic__img"/>
                <div className="loading-topic__user-info">
                    <div className="loading-topic__title"/>
                </div>
                <div className="loading-topic__info"/>
            </div>
            
            <div className="article__container">
                <div className="loading-topic__item loading-topic__item--padding_margin">
                    <div className="loading-topic__title"/>
                </div>
                <div className="loading-topic__item loading-topic__item--padding_margin">
                    <div className="loading-topic__title loading-topic__long"/>
                </div>
                <div className="loading-topic__item loading-topic__item--padding_margin">
                    <div className="loading-topic__big--image"/>
                </div>
                
                {renderDataUnit()}
                {renderDataUnit()}
                {renderDataUnit()}
            </div>
        </div>
    )
}