import axios from 'axios'
import { Dispatch } from 'redux'
import { UNDERGROUNDS_URL } from '../constants/API'
import { SelectUnderground, Types } from '../constants/types'

export const fetchUndergroundsStart = () => {
    return {
        type: Types.UNDERGROUND_START
    }
}

export const fetchUndergroundsSuccess = (labels: Array<SelectUnderground>) => {
    return {
        type: Types.UNDERGROUND_SUCCESS,
        payload: labels,
    }
}

export const fetchUndergroundsFail = () => {
    return {
        type: Types.UNDERGROUND_FAIL,
    }
}

export const fetchUndergrounds = async (dispatch: Dispatch) => {
    dispatch(fetchUndergroundsStart())

    await axios.get<Array<SelectUnderground>>(UNDERGROUNDS_URL)
        .then((response) => dispatch(fetchUndergroundsSuccess(response.data)))
        .catch(() => dispatch(fetchUndergroundsFail()))
}