const BASE_URL = process.env.REACT_APP_BASE_URL

export const BASE_API = `${BASE_URL}/api`

export const TOPICS_URL = `${BASE_API}/topics?page=:page&limit=:limit`
export const TOPICS_FILTERS_URL = `${BASE_API}/topics?page=:page&limit=:limit`
export const NEWS_URL = `${BASE_API}/news`
export const NEWS_ITEM_URL = `${BASE_API}/news/:id`
export const NEWS_COMMENT_URL = `${BASE_API}/news/:id/comment`
export const TOPIC_URL = `${BASE_API}/topic/:id`
export const TOPIC_STORE_URL = `${BASE_API}/topic/store`
export const TOPIC_UPDATE_URL = `${BASE_API}/topic/update`
export const COMMENT_URL = `${BASE_API}/topic/:id/comment`
export const RATING_URL = `${BASE_API}/topic/:id/rating`

export const LABELS_URL = `${BASE_API}/labels`
export const UNDERGROUNDS_URL = `${BASE_API}/undergrounds`
export const TYPE_FLATS_URL = `${BASE_API}/type-flats`

export const REGISTER_URL = `${BASE_API}/register`
export const LOGIN_URL = `${BASE_API}/login`

export const UPLOAD_IMG_URL = `${BASE_API}/upload-images`

export const DADATA_URL = 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address'

export const LIMIT = 10