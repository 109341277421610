import React, { Component } from 'react'
import axios, { AxiosError, AxiosResponse } from 'axios'
import Cookies from 'js-cookie'
import Select from 'react-select'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { AddressSuggestions, DaDataAddress, DaDataSuggestion } from 'react-dadata'
import { Modal } from 'react-bootstrap'
import { Response, TopicItem, TypeFlats } from '../../constants/types'
import { DADATA_URL, TOPIC_STORE_URL, TOPIC_UPDATE_URL, UPLOAD_IMG_URL } from '../../constants/API'
import { AppState } from '../../reducers'
import { fetchLabels } from '../../actions/LabelActions'
import { Label } from '../../reducers/LabelReducer'
import { colorStyles } from './CreateTopic.utils'
import { ImagePreview } from '../ImagePreview'
import { ResponseImages } from '../ImagePreview/ImagePreview'
import { validationSchema } from './CreateTopic.validate'
import { fetchTypeFlats } from '../../actions/TypeFlatsActions'

interface Props {
    modalShow: boolean,
    onHide: () => void,
    getLabels: () => void,
    getFlats: () => void,
    loading: boolean,
    labels: Array<Label>,
    typeFlats: Array<TypeFlats>
    editMode: boolean,
    editData: TopicItem | undefined,
}

interface State {
    id?: number,
    address: DaDataSuggestion<DaDataAddress> | undefined,
    title: string,
    text: string,
    square: string,
    floor: string,
    floorsInHouse: string,
    roomsCount: string,
    cost: string,
    label: number | null,
    typeFlat: number | null,
    images: Array<string>,
    link: string,
    btnDisabled: boolean,
    valid: boolean | undefined,
    error: number | undefined,
}

class CreateTopic extends Component<Props, State> {
    
    static defaultProps = {
        editMode: false,
        editData: undefined,
    }
    
    state: State = {
        id: undefined,
        address: undefined,
        title: '',
        text: '',
        square: '',
        floor: '',
        floorsInHouse: '',
        cost: '',
        roomsCount: '',
        link: '',
        label: null,
        typeFlat: null,
        images: [],
        btnDisabled: false,
        valid: undefined,
        error: undefined,
    }    
    
    inputFile = React.createRef<HTMLInputElement>()
    
    componentDidMount() {
        const { labels, typeFlats, getFlats, getLabels, editMode, editData } = this.props
        
        if (!labels.length) {
            getLabels()
        }
        
        if (!typeFlats.length) {
            getFlats()
        }
        
        if (editMode && editData) {
            
            axios.post<Array<DaDataSuggestion<DaDataAddress>>>(DADATA_URL, { query: editData.address, count: 1 }, {
                headers: { 'authorization': `Token ${process.env.REACT_APP_DADATA_TOKEN as string}` }
            }).then((response) => {
                this.setState({
                    id: editData.id,
                    title: editData.title,
                    text: editData.text,
                    // @ts-ignore
                    address: response.data.suggestions[0],
                    images: editData.images.map((i) => i.name),
                    label: editData.label_id,
                    cost: String(editData.cost),
                    roomsCount: String(editData.room_count),
                    floor: String(editData.floor),
                    square: String(editData.square),
                    link: editData.link,
                    floorsInHouse: String(editData.floors_in_house),
                    typeFlat: +typeFlats.find((f) => f.name === editData.type_flat_name)!.id_type,
                })
            })
        }
        
        this.setState({ error: undefined, valid: undefined })
    }

    onSelectAddress = (address: DaDataSuggestion<DaDataAddress> | undefined) => {
        this.setState({ address })   
    }
    
    onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        
        const { id, title, text, address, label, typeFlat, floor, cost, roomsCount, square, floorsInHouse, link, images } = this.state
        const { onHide, editMode } = this.props
        
        const data = {
            id,
            login: Cookies.get('id'),
            title,
            text,
            floor: Number(floor),
            square: Number(square),
            cost: Number(cost),
            room_count: Number(roomsCount),
            address: address?.value,
            house: address?.data?.house,
            geo_lat: address?.data.geo_lat,
            geo_lon: address?.data.geo_lon,
            id_label: Number(label),
            link,
            type_flat: typeFlat,
            floors_in_house: Number(floorsInHouse),
            images,
        }
        
        const isValid = validationSchema.isValidSync(data)
        
        this.setState({ valid: isValid })

        if (isValid) {
            const url = editMode ? TOPIC_UPDATE_URL : TOPIC_STORE_URL
            axios.post<Response>(url, data).then((_) => {
                onHide()
                window.location.reload()
            }).catch((err: AxiosError) => {
                this.setState({
                    error: err.response?.status
                })
            })
        }
    }
    
    onChangeInput = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { value, name } = e.target

        this.setState({
            [name]: value
        } as Pick<State, 'title' | 'text'>)
    }
    
    onSelectLabel = (value: any) => {
        this.setState({ label: value.value })
    }
    
    onSelectTypeFlat = (value: any) => {
        this.setState({ typeFlat: value.value })
    }
    
    onChangeFiles = (e: React.ChangeEvent<HTMLInputElement>) => {
        // @ts-ignore
        const files = Array.from(e.target.files)
        const maxFiles = 10
        const maxSizeFile = 2097152 // 2 Mb
        const { images } = this.state
        
        const sizeFileOk = (files || []).every((f) =>  f.size <= maxSizeFile)
        
        if (sizeFileOk && (files.length + images.length) <= maxFiles) {
            this.setState({ btnDisabled: true })
            const { images } = this.state
            const formData = new FormData()

            files.forEach((file, i) => {
                formData.append(String(i), file)
            })

            axios({
                method: 'POST',
                url: UPLOAD_IMG_URL,
                data: formData,
                headers: { "Content-Type": "multipart/form-data" },
            }).then((res: AxiosResponse<ResponseImages>) => {
                this.setState({ images: [ ...images, ...res.data ], btnDisabled: false })
            }).catch((err) => {
                console.error(err)
                this.setState({ btnDisabled: false })
            })   
        }
    }
    
    onRemoveImage = (e: React.MouseEvent<HTMLSpanElement>, img: string) => {
        e.preventDefault()
        
        const { images } = this.state
        
        this.setState({
            images: images.filter((i) => i !== img)
        })
    }
    
    render() {
        const { modalShow, onHide, loading, labels, typeFlats, editMode } = this.props
        const { 
            text, error, title, square, floor, 
            floorsInHouse, address, images, 
            label, roomsCount, cost, 
            typeFlat, link, valid, btnDisabled,
        } = this.state

        if (loading) return null
        
        const labelsList = labels.map(l => ({ value: l.id, label: l.name, color: l.color }))
        const typeFlatsList = typeFlats.map(l => ({ value: l.id_type, label: l.name, color: '#00583f' }))
        
        return (
            <Modal show={ modalShow }
                   onHide={ onHide }
                   dialogClassName="modal-create"
            >
                <Modal.Header closeButton>
                    <Modal.Title className="">{editMode ? 'Изменить тему' : 'Создать тему'}</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <form className="modal-create__body" onSubmit={this.onSubmit}>
                        <div className="modal-create__row">
                            <div className="field modal-create__field">
                                <label className="field" htmlFor="title">Заголовок</label>
                                <input className="field" value={title} onChange={this.onChangeInput} type="text" name="title"/>
                            </div>
                            <div className="field modal-create__field modal-create__field_address">
                                <label className="field" htmlFor="title">Адрес</label>
                                <AddressSuggestions
                                    token={process.env.REACT_APP_DADATA_TOKEN as string}
                                    inputProps={{ className: 'field' }}
                                    value={address}
                                    minChars={3}
                                    count={6}
                                    highlightClassName="modal-create__field_highlight"
                                    onChange={this.onSelectAddress}
                                />
                            </div>
                        </div>
                        <div className="modal-create__row">
                            <div className="field modal-create__field">
                                <label className="field" htmlFor="label">Метка</label>
                                <Select 
                                    value={labelsList.find((l) => l.value === label)}
                                    options={labelsList}
                                    styles={colorStyles}
                                    onChange={this.onSelectLabel}
                                    placeholder=""
                                />
                            </div>
                            <div className="modal-create__row">
                                <div className="field modal-create__field modal-create__field_mini">
                                    <label className="field" htmlFor="floor">Этаж</label>
                                    <input className="field" value={floor} onChange={this.onChangeInput} type="number" name="floor"/>
                                </div>
                                <div className="field modal-create__field modal-create__field_mini">
                                    <label className="field" htmlFor="floorsInHouse">Этажей в доме</label>
                                    <input className="field" value={floorsInHouse} onChange={this.onChangeInput} type="number" name="floorsInHouse"/>
                                </div>
                            </div>
                        </div>
                        <div className="modal-create__row">
                            <div className="field modal-create__field">
                                <label className="field" htmlFor="label">Тип жилья</label>
                                <Select
                                    value={typeFlatsList.find((l) => +l.value === typeFlat)}
                                    options={typeFlatsList}
                                    styles={colorStyles}
                                    onChange={this.onSelectTypeFlat}
                                    placeholder=""
                                />
                            </div>
                            <div className="modal-create__row">
                                <div className="field modal-create__field modal-create__field_mini">
                                    <label className="field" htmlFor="square">Общая площадь</label>
                                    <input className="field" value={square} onChange={this.onChangeInput} type="number" name="square"/>
                                </div>
                                <div className="field modal-create__field modal-create__field_mini">
                                    <label className="field" htmlFor="roomsCount">Количество комнат</label>
                                    <input className="field" value={roomsCount} onChange={this.onChangeInput} type="number" name="roomsCount"/>
                                </div>
                            </div>
                        </div>
                        <div className="modal-create__row">
                            <div className="field modal-create__field">
                                <label className="field" htmlFor="cost">Цена</label>
                                <input className="field" value={cost} onChange={this.onChangeInput} type="number" name="cost"/>
                            </div>
                        </div>
                        <div className="modal-create__row">
                            <div className="field modal-create__field">
                                <label className="field" htmlFor="link">Ссылка на объект недвижимости</label>
                                <input className="field" value={link} onChange={this.onChangeInput} type="text" name="link"/>
                            </div>
                        </div>
                        <div className="modal-create__textarea">
                            <label className="field" htmlFor="text">Сообщение</label>
                            <textarea name="text" id="text" value={text} onChange={this.onChangeInput} className="field">
                        </textarea>
                        </div>
                        
                        <div className="modal-create__images">
                            <ImagePreview
                                onChangeFiles={this.onChangeFiles}
                                onRemoveImg={this.onRemoveImage}
                                images={images}
                                multiple
                            />
                            <div className="modal-create__info">Максимальное количество файлов - 10</div>
                            <div className="modal-create__info">Максимальный размер каждого файла - 2 Мб</div>
                        </div>

                        <div className="text-right modal-create__btn-wrapper">
                            <button disabled={btnDisabled} className="button button-link modal-create__btn" type="submit">
                                {editMode ? 'Изменить тему' : 'Создать тему'}
                            </button>
                            {valid === false && (
                                <div className="modal-create__error">Проверьте правильность заполнения данных!</div>  
                            )}
                            {error === 401 && (
                                <div className="modal-create__error">Вы не авторизованы!</div>
                            )}
                            {error === 500 && (
                                <div className="modal-create__error">Прозиошла ошибка на стороне сервера!</div>
                            )}
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        )
    }
}

const mapStateToProps = (state: AppState) => {
    return {
        ...state.labels,
        ...state.flats
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        getLabels: () => fetchLabels(dispatch),
        getFlats: () => fetchTypeFlats(dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateTopic)