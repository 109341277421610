import React from 'react'

const Topic = () => {
    return (
        <div className="loading-topic__item loading-topic__item--shadow">
            <div className="loading-topic__img"/>
            <div className="loading-topic__user-info">
                <div className="loading-topic__title"/>
            </div>
            <div className="loading-topic__info"/>
        </div>
    )
}

type Props = {
    count: number
}

const LoadingTopic: React.FC<Props> = ({ count }) => {
    return (
        <div className="loading-topic">
            {[...Array(count)].map((_, idx) => <Topic key={ idx }/>)}
        </div>
    )
}

export default LoadingTopic