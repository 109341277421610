import React from 'react'

const News = () => {
    return (
        <div className="loading-topic__item loading-topic__item--shadow">
            <div className="loading-topic__user-info">
                <div className="loading-topic__title"/>
            </div>
        </div>
    )
}

type Props = {
    count: number
}

const LoadingNews: React.FC<Props> = ({ count }) => {
    return (
        <div className="loading-topic">
            {[...Array(count)].map((_, idx) => <News key={ idx }/>)}
        </div>
    )
}

export default LoadingNews