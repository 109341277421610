import React, { useEffect } from 'react'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { Welcome } from '../components/Welcome'
import { SideBar } from '../components/SideBar'
import { CONTAINER_WIDTH } from '../constants/layout'
import { LoadingTopic, Topic } from '../components/Topic'
import { clearTopics, fetchTopics } from '../actions/TopicActions'
import { AppState } from '../reducers'
import { TopicState } from '../reducers/TopicReducer'

type Props = {
    fetchTopics: (page: number, limit: number) => void
    clearTopics: () => void
}

const MainMenu: React.FC<Props & TopicState> = ({ fetchTopics, clearTopics, loading, error, topics }) => {
    
    useEffect(() => {
        fetchTopics(1, 5)
        
        return () => clearTopics()
    }, [])
    
    return (
        <main>
            <Welcome/>
            
            <SideBar/>
            
            <div className="topics" style={{ width: `${CONTAINER_WIDTH - 265}px` }}>
                <div className="topics__title" key={ 0 }>
                    <h3>Последние публикации</h3>
                </div>
                {loading 
                    ? <LoadingTopic count={5}/> 
                    : topics.map((topic) => <Topic key={topic.id} {...topic}/>)
                }
                {error && <div className="topics__not-found">Произошла ошибка при получении данных!</div>}
            </div>
        </main>
    )
}

const mapStateToProps = (state: AppState) => {
    return {
        ...state.topics
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        fetchTopics: (page: number, limit: number) => fetchTopics(dispatch, page, limit),
        clearTopics: () => dispatch(clearTopics())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MainMenu)