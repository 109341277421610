import React, { Component } from 'react'
import { SideBar } from '../components/SideBar'
import { CONTAINER_WIDTH } from '../constants/layout'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { News, Response } from '../constants/types'
import { COMMENT_URL, NEWS_COMMENT_URL, NEWS_ITEM_URL } from '../constants/API'
import axios, { AxiosError } from 'axios'
import { AppState } from '../reducers'
import { getIsAuthenticated } from '../selectors/AuthSelectors'
import { connect } from 'react-redux'
import Cookies from 'js-cookie'
import { Comment } from '../components/Comment'

interface URLParams {
    id: string
}

interface Props extends RouteComponentProps<URLParams> {
    isAuthenticated: boolean   
}

interface State {
    loading: boolean
    error: string
    news: News | null
    textarea: string
}

class NewsItemPage extends Component<Props, State> {
    state: State = {
        loading: false,
        error: '',
        news: null,
        textarea: ''
    }
    
    componentDidMount() {
        this.getNews()
    }

    onChangeTextArea = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        e.preventDefault()

        const { value } = e.target

        this.setState({
            textarea: value
        })
    }

    onSubmitComment = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()

        const { textarea } = this.state

        this.onSubmit(textarea)
    }

    onSubmit = async (text: string) => {
        const { match } = this.props
        const newsId = match.params.id
        const url = NEWS_COMMENT_URL.replace(':id', newsId)
        
        if (!!text) {
            await axios.post<Response>(url, {
                login: Cookies.get('id'),
                text,
                newsId,
            }).then((_) => {
                this.setState({ textarea: '' })
                this.getNews()
            })   
        }
    }
    
    getNews = async () => {
        const { match } = this.props
        
        const url = NEWS_ITEM_URL.replace(':id', match.params.id)
        
        await axios.get<News>(url)
            .then((response) => this.setState({ news: response.data, loading: false }))
            .catch((err: AxiosError) => {
                if (err.response?.status === 404) {
                    this.setState({ error: 'Новость не найдена!' })
                } else this.setState({ error: 'Произошла ошибка при получении данных!' })
            })
    }

    render() {
        const { loading, error, news, textarea } = this.state
        const { isAuthenticated } = this.props
        
        return (
            <main>
                <SideBar/>

                <div className="article topic" style={{ width: `${CONTAINER_WIDTH - 265}px` }}>
                    {!!error ? <div className="article__title text-center">{error}</div> : loading || !news ? <div>Загрузка</div> : (
                        <div className="article__mini-wrapper">
                            <div className="article__title">{news.title}</div>
                            
                            <div className="article__text">{news.text}</div>

                            <div className="article__separator"/>
                            
                            <div className="article__comments">
                                <div className="article__count">Комментарии ({news.comments?.length || 0})</div>

                                <form className="comment__form comment__form_main" onSubmit={this.onSubmitComment}>
                                    <span>Ваше сообщение</span>

                                    <div>
                                    <textarea className="field" value={textarea} onChange={this.onChangeTextArea} disabled={!isAuthenticated}>
                                    </textarea>

                                        <div className="comment__buttons">
                                            <button className="button button-link comment__button comment__button-send" type="submit" disabled={!isAuthenticated}>
                                                Отправить
                                            </button>
                                        </div>
                                    </div>
                                </form>
                                
                                {
                                    news.comments?.map((comment) => (
                                        <Comment
                                            key={comment.id}
                                            login={comment.login}
                                            text={comment.text}
                                            date={comment.date}
                                            time={comment.time}
                                            img={comment.img}
                                            isAuthenticated={isAuthenticated}
                                            hideAnswerBtn
                                            onSubmit={() => {}}
                                        />
                                    ))
                                }
                            </div>
                        </div>
                    )}
                </div>
            </main>
        )
    }
}

const mapStateToProps = (state: AppState) => {
    const { user: { id, token } } = state

    return {
        isAuthenticated: getIsAuthenticated(id, token)
    }
}

export default connect(mapStateToProps)(withRouter(NewsItemPage))