import React, { CSSProperties } from 'react'
import chroma from 'chroma-js'
import clsx from 'clsx'

interface Props {
    color: string,
    name: string,
    mini?: boolean,
    textColor?: string,
}

const Label: React.FC<Props> = ({ color, name, mini, textColor  }) => {
    const chromaColor = chroma(color)
    
    const styles: CSSProperties = {
        backgroundColor: color,
        color: !textColor 
            ? chroma.contrast(chromaColor, 'white') > 2 ? 'white' : 'black'
            : textColor
    }
    
    return (
        <div className={clsx('label', mini && 'label__mini')} style={ styles }>
            {name}
        </div>
    )
}

Label.defaultProps = {
    mini: false,
    textColor: '',
}

export default Label