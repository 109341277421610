import React, { useEffect, useMemo } from 'react'
import { connect } from 'react-redux'
import { SideBar } from '../components/SideBar'
import { LoadingTopic, Topic } from '../components/Topic'
import { CONTAINER_WIDTH } from '../constants/layout'
import { Filters } from '../components/Filters'
import { AppState } from '../reducers'
import { Dispatch } from 'redux'
import { fetchTopics, selectSortTypeAndFetchTopics } from '../actions/TopicActions'
import { fetchUndergrounds } from '../actions/UndergroundActions'
import { TopicState } from '../reducers/TopicReducer'
import { UndergroundState } from '../reducers/UndergroundReducer'
import useInfiniteScroll from '../hooks/useInfiniteScroll'
import sortTypes from '../constants/sorts'
import { LIMIT } from '../constants/API'
import Select from 'react-select'

type Props = {
    fetchTopics: (page: number) => void
    fetchUndergrounds: () => void
    selectSortTypeAndFetchTopics: (value: any) => void
}

const TopicsPage: React.FC<Props & TopicState & UndergroundState> = ({ 
fetchTopics, fetchUndergrounds, selectSortTypeAndFetchTopics, loading, topicsNotFound, 
error, sortType, topics, page, more }) => {
    useEffect(() => {
        fetchUndergrounds()
        fetchTopics(1)
    }, [])

    useInfiniteScroll(more && page > 1 && !error, fetchTopics.bind(null, page, LIMIT))
    
    const sorts = useMemo(() => sortTypes, [])
    
    return (
        <main>
            
            <Filters/>
            
            <SideBar/>

            <div className="topics" style={{ width: `${CONTAINER_WIDTH - 265}px` }}>
                <div className="topics__title">
                    <h3>Публикации</h3>
                    <Select
                        value={sorts.filter((s) => s.value === sortType)}
                        onChange={(value: any) => selectSortTypeAndFetchTopics(value)}
                        options={sorts}
                        placeholder="Сортировать"
                    />
                </div>
                {topicsNotFound && !loading && (
                    <div className="topics__not-found">Нет публикаций!</div>
                )}
                {(loading || (!loading && !topics.length)) && !error && !topicsNotFound ? <LoadingTopic count={page * LIMIT}/> 
                    : topics.map((topic) => <Topic key={topic.id} {...topic}/>)
                }
                {error && <div className="topics__not-found">Произошла ошибка при получении данных!</div>}
            </div>
        </main>
    )
}

const mapStateToProps = (state: AppState) => {
    return {
        ...state.topics,
        ...state.undergrounds,
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        fetchTopics: (page: number) => fetchTopics(dispatch, page),
        fetchUndergrounds: () => fetchUndergrounds(dispatch),
        selectSortTypeAndFetchTopics: (value: any) => selectSortTypeAndFetchTopics(dispatch, value)
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(TopicsPage)