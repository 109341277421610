import axios from 'axios'
import { News, Types } from '../constants/types'
import { Dispatch } from 'redux'
import { NEWS_URL } from '../constants/API'

export const fetchNewsStart = () => {
    return {
        type: Types.FETCH_NEWS_START
    }
}

export const fetchNewsSuccess = (news: Array<News>) => {
    return {
        type: Types.FETCH_NEWS_SUCCESS,
        payload: news
    }
}

export const fetchNews = async (dispatch: Dispatch) => {
    dispatch(fetchNewsStart())
    
    await axios.get<Array<News>>(NEWS_URL)
        .then((response) => dispatch(fetchNewsSuccess(response.data)))
}