import { TypeFlats, Types } from '../constants/types'

export type TypeFlatsState = {
    loading: boolean,
    error: boolean,
    typeFlats: Array<TypeFlats>,
}

const initialState: TypeFlatsState = {
    loading: false,
    error: false,
    typeFlats: []
}

const TypeFlatsReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case Types.TYPE_FLATS_START:
            return {
                ...state,
                loading: true,
            }
        case Types.TYPE_FLATS_SUCCESS:
            return {
                ...state,
                loading: false,
                typeFlats: action.payload,
            }
        case Types.TYPE_FLATS_FAIL:
            return {
                ...state,
                loading: false,
                error: true
            }
        default:
            return state
    }
}

export default TypeFlatsReducer