import React from 'react'
import { SideBar } from '../components/SideBar'
import { CONTAINER_WIDTH } from '../constants/layout'
import { Accordion } from '../components/Accordion'

const HelpPage: React.FC = () => {
    return (
        <main>
            <SideBar/>
            
            <div className="topics" style={{ width: `${CONTAINER_WIDTH - 265}px` }}>
                <div className="topics__title">
                    <h3>Помощь</h3>
                </div>
                
                <Accordion 
                    title="Как можно опубликовать тему?"
                    text="Для публикации темы вы должны зарегистрироваться или в войти в систему. После входа на правой панели появится соответствующая кнопка создание темы."
                />
                <Accordion
                    title="Как снять тему с публикации?"
                    text="К сожалению, в данный момент снять тему с публикации невозможно."
                />
                <Accordion
                    title="Почему мой поиск не даёт результатов?"
                    text="Ваш поисковый запрос, возможно, был слишком неопределённым и включал много общих слов. Будьте более конкретны."
                />
                <Accordion
                    title="Почему я не могу войти?"
                    text="Существует несколько возможных причин. Прежде всего убедитесь, что вы правильно вводите имя пользователя и пароль. Также возможно, что допущена ошибка при регистрации, тогда вам придется заново зарегистрироваться."
                />
            </div>
        </main>
    )
}

export default HelpPage