import axios, { AxiosResponse } from 'axios'
import { Dispatch } from 'redux'
import { Topic, Types } from '../constants/types'
import { LIMIT, TOPICS_URL } from '../constants/API'
import store from '../store/store'
import React from 'react'

export const fetchTopicsStart = (page: number) => {
    return {
        type: Types.FETCH_TOPICS_START,
        payload: page,
    }
}

export const fetchTopicsSuccess = (topics: Array<Topic>) => {
    return {
        type: Types.FETCH_TOPICS_SUCCESS,
        payload: topics,
    }
}

export const fetchTopicsFail = () => {
    return {
        type: Types.FETCH_TOPICS_FAIL
    }
}

export const clearTopics = () => {
    return {
        type: Types.CLEAR_TOPICS,
    }
}

export const fetchTopics = async (dispatch: Dispatch, page: number, limit: number = LIMIT) => {
    dispatch(fetchTopicsStart(page))
    
    const url = TOPICS_URL.replace(':page', String(page)).replace(':limit', String(limit))
    
    const state = store.getState().topics
    
    await axios.post<Array<Topic>>(url, {
        label: state.filterLabel ? String(state.filterLabel) : null,
        metro: state.filterUnderground ? String(state.filterUnderground) : null,
        maxPrice: state.filterMaxPrice,
        minPrice: state.filterMinPrice,
        rooms: state.filterRooms,
        title: state.filterTitle,
        sort: state.sortType ? state.sortType : null,
    }).then((response) => dispatch(fetchTopicsSuccess(response.data)))
        .catch(() => dispatch(fetchTopicsFail()))
}

export const resetAndFetchTopics = async (dispatch: Dispatch, page: number, limit: number = LIMIT) => {
    dispatch(resetFilters())
    
    fetchTopics(dispatch, page, limit)
}

export const resetFilters = () => {
    return {
        type: Types.RESET_FILTERS
    }
}

export const selectLabel = (value: any) => {
    return {
        type: Types.FILTER_TOPICS_LABEL,
        payload: value.value
    }
}

export const selectUnderground = (value: any) => {
    return {
        type: Types.FILTER_TOPICS_UNDERGROUND,
        payload: value.value
    }
}

export const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target
    
    return {
        type: name,
        payload: value
    }
}


export const selectSortTypeAndFetchTopics = (dispatch: Dispatch, value: any) => {
    dispatch({
        type: Types.SORT_TOPICS,
        payload: value.value
    })
    
    fetchTopics(dispatch, 1)
}