import React, { useCallback } from 'react'
import { Modal } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { exit } from '../../../actions/SessionActions'

type Props = {
    show: boolean,
    onHide: () => void
}

const Popup: React.FC<Props> = ({ show, onHide }) => {
    const dispatch = useDispatch()
    const history = useHistory()
    
    const onExit = useCallback(() => {
        setTimeout(() => {
            dispatch(exit())
            history.go(0)
            onHide()
        }, 500)
    }, [onHide])
    
    return (
        <Modal show={ show } onHide={ onHide } animation={ false } dialogClassName="popup">
            <div className="arrow-up"/>
            <ul className="popup__container">
                <li onClick={ onExit }>
                    Выйти
                </li>
            </ul>
        </Modal>
    )
}

export default Popup