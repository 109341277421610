import React from 'react'

const Footer: React.FC = () => {
    return (
        <footer className="footer">
            <div className="footer__name">
                Специализированный сайт для оценки и обсуждения товаров (на примере объектов <b>недвижимости</b>)
            </div>
            
            <div className="footer__author">
                Абдуллоев Парвиз - 2021
            </div>
        </footer>
    )
}

export default Footer