import React, { useMemo } from 'react'
import Select from 'react-select'
import { connect, useDispatch } from 'react-redux'
import chroma from 'chroma-js'
import { AppState } from '../../reducers'
import { colorStyles } from '../CreateTopic/CreateTopic.utils'
import { Label } from '../../reducers/LabelReducer'
import {
    fetchTopics,
    onChangeInput, resetAndFetchTopics,
    selectLabel,
    selectUnderground
} from '../../actions/TopicActions'
import { Dispatch } from 'redux'
import { SelectUnderground, Types } from '../../constants/types'
import { TopicState } from '../../reducers/TopicReducer'

interface Props {
    labels: Array<Label>
    undergrounds: Array<SelectUnderground>
    fetchTopics: (page: number) => void
    resetFilters: (page: number) => void
}

const Filters: React.FC<Props & TopicState> = ({ 
labels: labelsList, undergrounds: undergroundsList, resetFilters,
filterLabel, filterUnderground, filterMinPrice, filterMaxPrice, filterRooms, filterTitle, 
fetchTopics }) => {
    const dispatch = useDispatch()

    console.log(filterLabel)
    
    const labels = useMemo(() => {
        return labelsList.map(l => ({ value: l.id, label: l.name, color: l.color }))
    }, [labelsList])
    
    const undergrounds = useMemo(() => {
        return undergroundsList.map(l => ({ value: l.underground_id, label: l.underground_name, color: chroma(l.branch_color).hex() }))
    }, [undergroundsList])

    return (
        <div className="filters">
            <div className="filters__row">
                <div className="field filters__field">
                    <label className="field" htmlFor="title">Заголовок</label>
                    <input className="field" 
                           type="text" 
                           maxLength={32} 
                           onChange={(e) => dispatch(onChangeInput(e))} 
                           name={Types.FILTER_TOPICS_TITLE} 
                           value={filterTitle} 
                    />
                </div>
                <div className="field filters__field">
                    <label className="field" htmlFor="label">Метка</label>
                    <Select
                        value={labels.find((l) => l.value === filterLabel) || null}
                        options={labels}
                        styles={colorStyles}
                        onChange={(value: any) => dispatch(selectLabel(value))}
                        placeholder=""
                    />
                </div>
                <div className="field filters__field">
                    <label className="field" htmlFor="underground">Метро</label>
                    <Select
                        value={undergrounds.filter((u) => u.value === filterUnderground) || null}
                        options={undergrounds}
                        styles={colorStyles}
                        onChange={(value: any) => dispatch(selectUnderground(value))}
                        placeholder=""
                    />
                </div>
            </div>
            
            <div className="filters__row">
                <div className="field filters__field">
                    <label className="field" htmlFor="minPrice">Цена, от</label>
                    <input className="field" 
                           type="number" 
                           maxLength={32} 
                           onChange={(e) => dispatch(onChangeInput(e))} 
                           name={Types.FILTER_TOPICS_MIN_PRICE} 
                           value={filterMinPrice} 
                    />
                </div>
                <div className="field filters__field">
                    <label className="field" htmlFor="maxPrice">Цена, до</label>
                    <input className="field" 
                           type="number" 
                           maxLength={32} 
                           onChange={(e) => dispatch(onChangeInput(e))} 
                           name={Types.FILTER_TOPICS_MAX_PRICE} 
                           value={filterMaxPrice}
                    />
                </div>
                <div className="field filters__field">
                    <label className="field" htmlFor="rooms">Количество комнат</label>
                    <input className="field" 
                           type="number" 
                           maxLength={32} 
                           onChange={(e) => dispatch(onChangeInput(e))} 
                           name={Types.FILTER_TOPICS_ROOMS} 
                           value={filterRooms}
                    />
                </div>
            </div>

            <div className="filters__row filters__row--center">
                <div className="field filters__field filters__field-auto">
                    <button className="button button-link filters__button-reset" onClick={() => resetFilters(1)}>
                        Очистить все
                    </button>
                </div>
                <div className="field filters__field">
                    <button className="button button-link filters__button" onClick={() => fetchTopics(1)}>
                        Показать публикации
                    </button>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state: AppState) => {
    return {
        ...state.labels,
        ...state.undergrounds,
        ...state.topics,
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        fetchTopics: (page: number) => fetchTopics(dispatch, page),
        resetFilters: (page: number) => resetAndFetchTopics(dispatch, page), 
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Filters)