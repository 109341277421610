import dayjs from 'dayjs'

type DateDiffType = {
    year:	number,
    month:	number,
    day:	number,
    hour:	number,
    minute:	number,
    second:	number
}

const dateDiffDef: DateDiffType = {
    year:		31536000000,
    month:		2592000000,
    day:		86400000,
    hour:		3600000,
    minute:		60000,
    second:		1000
}

const getDifferenceBetweenDateAndToday = (
    dateStart: Date,
    ...units: string[]
) => {
    const dateEnd = dayjs().toDate()

    let delta = Math.abs(dateStart.getTime() - dateEnd.getTime())

    return (units.length ? units : Object.keys(dateDiffDef))
        .reduce((res: object, key: string) => {
            if (!dateDiffDef.hasOwnProperty(key)) {
                throw new Error('Unknown unit in dateDiff: ' + key)
            }
            
            // @ts-ignore
            res[key] = Math.floor(delta / dateDiffDef[key])
            // @ts-ignore
            delta -= res[key] * dateDiffDef[key]
            
            return res
        }, {}) as DateDiffType
}

export const plural = (val: number, words: string[]) => {
    const value = (Math.abs(val) % 100)
    const division = value % 10

    if (value > 10 && value < 20) return `${val} ${words[2]}`
    if (division > 1 && division < 5) return `${val} ${words[1]}`
    if (division == 1) return `${val} ${words[0]}`

    return `${val} ${words[2]}`
}

export const getDifferenceBetweenDateAndTodayText = (date: string, time: string) => {
    const {
        year,
        month,
        day,
        hour,
        minute
    } = getDifferenceBetweenDateAndToday(dayjs(`${date}T${time}`).toDate())

    const diffYearsText   = year   ? plural(year, ['год', 'года', 'лет'])          : ''
    const diffMonthsText  = month  ? plural(month, ['месяц', 'месяца', 'месяцев']) : ''
    const diffDaysText    = day    ? plural(day, ['день', 'дня', 'дней'])          : ''
    let diffHoursText   = hour   ? plural(hour, ['час', 'часа', 'часов'])        : ''
    let diffMinutesText = minute ? plural(minute, ['минуту', 'минуты', 'минут']) : ''
    
    if (!!diffMonthsText) {
        diffHoursText = ''
        diffMinutesText = ''
    } else if (diffDaysText) {
        diffMinutesText = ''
    }
    
    if (diffYearsText || diffMonthsText || diffDaysText || diffHoursText || diffMinutesText) {
        return `опубликовал ${diffYearsText} ${diffMonthsText} ${diffDaysText} ${diffHoursText} ${diffMinutesText} назад`
    } 
    
    return 'опубликовал только что'
}