import React from 'react'

import commentsSVG from '../../../resources/comments.svg'
import viewsSVG from '../../../resources/views.svg'

interface IMetrics {
    views: number
    comments: number,
}

const Metrics: React.FC<IMetrics> = ({ views, comments }) => {
    return (
        <div className="metrics">
            <div className="metrics__comments">
                <img src={commentsSVG} alt="Комментарии"/>
                
                <span className="metrics__numbers">{ comments }</span>
            </div>

            <div className="metrics__views">
                <img src={viewsSVG} alt="Просмотры" style={{ width: '23px' }}/>

                <span className="metrics__numbers">{ views }</span>
            </div>
        </div>
    )
}

export default Metrics