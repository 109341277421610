import chroma from 'chroma-js'

export const colorStyles = {
    control: (styles: any) => {
        return {
            ...styles,
            height: '40px',
            borderRadius: '3px',
            border: '1px solid #D6D6D6',
            boxShadow: 'none',
            '&:hover': {
                borderColor: '1px solid #c4c4c4',
                outline: 'none',
                boxShadow: '0 0 4px rgb(0 0 0 / 25%)'
            },
        }
    },
    option: (styles: any, { data, isDisabled, isFocused, isSelected }: any) => {
        const color = chroma(data.color)
        return {
            ...styles,
            backgroundColor: isDisabled
                ? null
                : isSelected
                    ? data.color
                    : isFocused
                        ? color.alpha(0.1).css()
                        : null,
            color: isDisabled
                ? '#ccc'
                : isSelected
                    ? chroma.contrast(color, 'white') > 2
                        ? 'white'
                        : 'black'
                    : data.color,
            cursor: isDisabled ? 'not-allowed' : 'default',

            ':active': {
                ...styles[':active'],
                backgroundColor:
                    !isDisabled && (isSelected ? data.color : color.alpha(0.3).css()),
            },
        }
    },
    singleValue: (styles: any, { data }: any) => ({ ...styles, color: data.color }),
}