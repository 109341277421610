import { combineReducers } from 'redux'
import TopicReducer from './TopicReducer'
import NewsReducer from './NewsReducer'
import SessionReducer from './SessionReducer'
import LabelReducer from './LabelReducer'
import UndergroundReducer from './UndergroundReducer'
import TypeFlatsReducer from './TypeFlatsReducer'

const rootReducer = combineReducers({
    topics: TopicReducer,
    user: SessionReducer,
    labels: LabelReducer,
    undergrounds: UndergroundReducer,
    news: NewsReducer,
    flats: TypeFlatsReducer
})

type RootReducer = typeof rootReducer

export type AppState = ReturnType<RootReducer>

export default rootReducer