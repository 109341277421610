export default [
    {
        value: 'new',
        label: 'Сначала новые',
    },
    {
        value: 'old',
        label: 'Сначала старые',
    },
    {
        value: 'optimal_price',
        label: 'По наиболее оптимальной цене',
    },
    {
        value: 'distance_center',
        label: 'Близость к центру (только для г. Москвы)',
    },
]