import axios, { AxiosError } from 'axios'
import Cookies from 'js-cookie'
import { Dispatch } from 'redux'
import { LoginRequest, LoginResponse, RegisterRequest, RegisterResponse, Types } from '../constants/types'
import { LOGIN_URL, REGISTER_URL } from '../constants/API'


const loginSuccess = (data: LoginResponse) => {
    Cookies.set('token', data.token!, { expires: 365 })
    Cookies.set('id', data.user!.login, { expires: 365 })

    axios.defaults.headers.common['token'] = data.token!
    
    return {
        type: Types.LOGIN_SUCCESS,
        payload: {
            id: data.user!.login,
            token: data.token,
        }
    }
}

const loginFailure = (errorCode: number | null) => {
    return {
        type: Types.LOGIN_FAILURE,
        payload: errorCode
    }
}

const registerFailure = (erroCode: number | null) => {
    return {
        type: Types.REGISTER_FAILURE,
        payload: erroCode
    }
}

export const login = async (dispatch: Dispatch, data: LoginRequest) => {
    const { login, password } = data
    
    // dispatch(startLogin())
    
    await axios.post<LoginResponse>(LOGIN_URL, {
        login, password
    }).then((response) => dispatch(loginSuccess(response.data)))
      .catch((e: AxiosError) => {
          if (e.response) {
              dispatch((loginFailure(e.response.status)))
          } else {
              dispatch((loginFailure(500)))
          }
      })
}

export const register = async (dispatch: Dispatch, data: RegisterRequest) => {
    const { login, password, name, surname, image } = data
    
    await axios.post<RegisterResponse>(REGISTER_URL, {
        login, password, name, surname, image
    }).then((response) => {
        dispatch(loginSuccess(response.data))
    }).catch((e: AxiosError) => {
        if (e.response) {
            dispatch(registerFailure(e.response.status))
        } else {
            dispatch(registerFailure(500))
        }
    })
}

export const exit = () => {
    Cookies.remove('token')
    Cookies.remove('id')

    axios.defaults.headers.common['token'] = null
    
    return {
        type: Types.ON_EXIT
    }
}

export const initAuth = (dispatch: Dispatch) => {
    const token = Cookies.get('token')
    const login = Cookies.get('id')
    
    if (token && login) {
        dispatch(loginSuccess({
            token,
            user: { login },
            message: 'Пользователь уже авторизован!'
        }))
    }
}

export const clearError = (dispatch: Dispatch) => {
    dispatch(loginFailure(null))
}