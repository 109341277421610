import { Types } from '../constants/types'

type SessionState = {
    id: string | null,
    token: string | null,
    errorLogin: number | null,
    errorRegister: number | null
}

const initialState: SessionState = {
    id: null,
    token: null,
    errorLogin: null,
    errorRegister: null
}

const SessionReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case Types.LOGIN_SUCCESS:
            return {
                ...state,
                id: action.payload.id,
                token: action.payload.token
            }
        case Types.LOGIN_FAILURE:
            return {
                ...state,
                errorLogin: action.payload
            }
        case Types.REGISTER_FAILURE:
            return {
                ...state,
                errorRegister: action.payload
            }
        case Types.ON_EXIT:
            return {
                ...state,
                id: null,
                token: null,
                errorLogin: null,
                errorRegister: null
            }
        default:
            return state
    }
}

export default SessionReducer