import axios from 'axios'
import { Dispatch } from 'redux'
import { TypeFlats, Types } from '../constants/types'
import { TYPE_FLATS_URL } from '../constants/API'

export const fetchTypeFlatsStart = () => {
    return {
        type: Types.TYPE_FLATS_START
    }
}

export const fetchTypeFlatsSuccess = (types: Array<TypeFlats>) => {
    return {
        type: Types.TYPE_FLATS_SUCCESS,
        payload: types,
    }
}

export const fetchTypeFlatsFail = () => {
    return {
        type: Types.TYPE_FLATS_FAIL
    }
}

export const fetchTypeFlats = async (dispatch: Dispatch) => {
    dispatch(fetchTypeFlatsStart())
    
    await axios.get<Array<TypeFlats>>(TYPE_FLATS_URL)
        .then((r) => dispatch(fetchTypeFlatsSuccess(r.data)))
        .catch(() => dispatch(fetchTypeFlatsFail()))
}
