import { SelectUnderground, Types } from '../constants/types'

export type UndergroundState = {
    loading: boolean,
    error: boolean,
    undergrounds: Array<SelectUnderground>,
}

const initialState: UndergroundState = {
    loading: false,
    error: false,
    undergrounds: []
}

const UndergroundReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case Types.UNDERGROUND_START:
            return {
                ...state,
                loading: true,
            }
        case Types.UNDERGROUND_SUCCESS:
            return {
                ...state,
                loading: false,
                undergrounds: action.payload,
            }
        case Types.UNDERGROUND_FAIL:
            return {
                ...state,
                loading: false,
                error: true,
            }
        default:
            return state
    }
}

export default UndergroundReducer