import React, { useEffect, useLayoutEffect } from 'react'
import { connect } from 'react-redux'
import { Switch, Route, Redirect, useLocation } from 'react-router-dom'
import { Header } from '../Header'
import { Footer } from '../Footer'
import { HelpPage, MainMenu, TopicPage, TopicsPage } from '../../pages'
import { Dispatch } from 'redux'
import { initAuth } from '../../actions/SessionActions'
import NewsPage from '../../pages/NewsPage'
import NewsItemPage from '../../pages/NewsItemPage'

type Props = {
    initAuth: () => void
}

const App: React.FC<Props> = ({ initAuth }) => {
    const location = useLocation()
    
    useEffect(() => {
        initAuth()
    }, [])

    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    }, [location.pathname])


    return (
        <>
            <div className="container">
                <Header/>

                <Switch>
                    <Route path="/" component={ MainMenu } exact/>
                    <Route path="/publications" component={ TopicsPage } exact/>
                    <Route path="/publications/:id" component={ TopicPage }/>
                    <Route path="/help" component={ HelpPage } exact/>
                    <Route path="/news" component={ NewsPage } exact/>
                    <Route path="/news/:id" component={ NewsItemPage } exact/>
                    <Redirect to="/"/>
                </Switch>
            </div>
            
            <Footer/>
        </>
    )
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        initAuth: () => initAuth(dispatch)
    }
}

export default connect(null, mapDispatchToProps)(App)
