import { News, Types } from '../constants/types'

export type NewsState = {
    loading: boolean
    news: Array<News>
}

const initialState: NewsState = {
    loading: false,
    news: []
}

const NewsReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case Types.FETCH_NEWS_START:
            return {
                ...state,
                loading: true,
            }
        case Types.FETCH_NEWS_SUCCESS:
            return {
                ...state,
                loading: false,
                news: action.payload
            }
        
        default:
            return state
    }
}

export default NewsReducer