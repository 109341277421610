import React from 'react'

type Props = {
    title: string
    value?: string
    block?: boolean
}

const DataUnit: React.FC<Props> = ({ value, title, block, children }) => {
    return (
        <div className="data-unit" style={ block ? { display: 'block' } : { display: 'inline-block' } }>
            <div className="data-unit__title">{title || 'Нет данных'}</div>
            {value ? <div className="data-unit__value">{value}</div> : children}
        </div>
    )
}

const defaultProps: Props = {
    block: false,
    title: '',
    value: '',
}

DataUnit.defaultProps = defaultProps

export default DataUnit