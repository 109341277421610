import * as Yup from 'yup'

export const validationSchema = Yup.object().shape({
    login: Yup.string().required(),
    title: Yup.string().required(),
    text: Yup.string().notRequired().nullable(),
    floor: Yup.number().required().min(0).max(95).integer(),
    square: Yup.number().required().positive(),
    cost: Yup.number().required().positive(),
    room_count: Yup.number().required().min(1).max(100).integer(),
    address: Yup.string().required(),
    house: Yup.string().required(),
    geo_lat: Yup.string().required(),
    geo_lon: Yup.string().required(),
    id_label: Yup.number().required(),
    link: Yup.string().notRequired(),
    type_flat: Yup.number().required(),
    floors_in_house: Yup.number()
        .when('floor', {
            is: (val: number) => val >= 0,
            then: Yup.number().min(Yup.ref('floor')).max(95).integer(),
            otherwise: Yup.number().required().min(0).max(95).integer(),
        }),
    images: Yup.array().of(Yup.string()).notRequired(),
})