import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { SideBar } from '../components/SideBar'
import { CONTAINER_WIDTH } from '../constants/layout'
import { AppState } from '../reducers'
import { fetchNews } from '../actions/NewsActions'
import { Dispatch } from 'redux'
import { NewsState } from '../reducers/NewsReducer'
import { LoadingNews, News } from '../components/News'

type Props = {
    fetchNews: () => void,
}

const NewsPage: React.FC<Props & NewsState> = ({
    fetchNews, news, loading
}) => {

    useEffect(() => {
        fetchNews()
    }, [])

    return (
        <main>
            <SideBar/>

            <div className="topics" style={{ width: `${CONTAINER_WIDTH - 265}px` }}>
                <div className="topics__title">
                    <h3>Новости</h3>
                </div>
                {
                    loading ? (
                        <LoadingNews count={5}/>
                    ) : !news.length ? <div className="topics__not-found">Нет новостей</div>
                        : news.map((n) => (
                            <News
                                key={n.id_news}
                                id={n.id_news}
                                { ...n }
                            />
                        ))
                }
            </div>
        </main>
    )
}

const mapStateToProps = (state: AppState) => {
    return {
        ...state.news
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        fetchNews: () => fetchNews(dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewsPage)