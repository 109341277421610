import React from 'react'

const Welcome: React.FC = () => {
    return (
        <div className="search">
            <div className="search__text">
                Добро пожаловать на сайт по&nbsp;обсуждению&nbsp;недвижимости!
            </div>
        </div>
    )
}

export default Welcome