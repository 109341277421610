/**
 * @author Parviz Abdulloev <parviz23.10@inbox.ru>
 */

import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'

import * as Sentry from "@sentry/react"
import { Integrations } from "@sentry/tracing"

import { App } from "./components"

import './styles/main.scss'
import store from './store/store'

Sentry.init({
    dsn: "https://709aff6048da4bb6a0d90cb252cc279e@o378877.ingest.sentry.io/5774189",
    autoSessionTracking: true,
    integrations: [new Integrations.BrowserTracing()],
    environment: window.location.host,
    tracesSampleRate: 1.0,
})

ReactDOM.render(
    <Provider store={ store }>
        <Router>
            <App/>
        </Router>
    </Provider>,
    document.getElementById('root')
)