import React from 'react'
import clsx from 'clsx'

type Props = {
    name: string,
    distance: number,
    color: string,
}

const Underground: React.FC<Props> = ({ name, distance, color }) => {
    const calcDistance = distance >= 1000 
        ? `${Math.round((distance / 1000) * 10) / 10} км` 
        : `${Math.round(distance / 10) * 10} м`
    
    return (
        <div className={clsx('underground__item', color)}>
            <span>{name}</span>
            &nbsp;-&nbsp;
            <span className="underground__distance">{calcDistance}</span>
        </div>
    )
}

export default React.memo(Underground)