import React, { useCallback, useState } from 'react'
import clsx from 'clsx'
import { ucFirst } from '../../utils'
import dayjs from 'dayjs'

type Props = {
    parentId?: number | null,
    parent?: boolean,
    login: string,
    date: string,
    time: string,
    text: string,
    img: string,
    onSubmit: (text: string, parentId: number | null) => void,
    isAuthenticated: boolean,
    hideAnswerBtn?: boolean,
}

const Comment: React.FC<Props> = ({ 
parent, parentId, login, img, hideAnswerBtn,
text, date, time, isAuthenticated, onSubmit }) => {
    const [showForm, setShowForm] = useState(false)
    const [value, setValue] = useState('')
    
    const onSubmitComment = useCallback((e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        
        setShowForm(false)
        setValue('')
        onSubmit(value, parentId || null)
    }, [onSubmit, parentId, value])
    
    const dateTime = dayjs(`${date}T${time}`).format('DD.MM.YYYY, HH:mm')
    
    return (
        <div className={clsx('comment', parent && 'comment__parent')}>
            <div className="comment__main">
                <img src={`${process.env.REACT_APP_BASE_URL}/${img}`} alt={ucFirst(login)}/>
                
                <div className="comment__info">
                    <div className="comment__user">{ucFirst(login)} — <span>{dateTime}</span></div>
                    
                    <div className="comment__comment">{text}</div>
                    
                    {!hideAnswerBtn && (
                        <button className="comment__answer" onClick={ () => setShowForm((prevState => !prevState)) }>
                            Ответить
                        </button>  
                    )}
                </div>
            </div>
            
            {
                showForm && isAuthenticated && (
                    <form className="comment__form" onSubmit={onSubmitComment}>
                        <span>Ваше сообщение</span>

                        <div>
                            <textarea className="field" value={value} onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setValue(e.target.value)}/>

                            <div className="comment__buttons">
                                <button className="button button-link comment__button comment__button-reject" onClick={ () => setShowForm(false) }>
                                    Отменить
                                </button>
                                <button className="button button-link comment__button comment__button-send">
                                    Ответить
                                </button>
                            </div>
                        </div>
                    </form>       
                )
            }
        </div>
    )
}

Comment.defaultProps = {
    parentId: null,
    hideAnswerBtn: false,
}

export default Comment