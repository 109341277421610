import * as Yup from 'yup'

export const loginSchema = Yup.object().shape({
    login: Yup.string().required(),
    password: Yup.string().required(),
})

export const registerSchema = Yup.object().shape({
    login: Yup.string().required(),
    password: Yup.string().required(),
    surname: Yup.string().required(),
    name: Yup.string().required(),
    image: Yup.array().of(Yup.string()).nullable(),
})