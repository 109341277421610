import React, { useState } from 'react'
import plus from '../../resources/plus.svg'
import minus from '../../resources/minus.svg'

interface Props {
    title: string
    text: string
}

const Accordion: React.FC<Props> = ({ title, text }) => {
    const [expanded, setExpanded] = useState(false) 
    
    return (
        <div className="accordion">
            <div className="accordion__header" onClick={() => setExpanded((exp) => !exp)}> 
                <button className="accordion__btn">
                    {expanded ? <img src={minus} alt={minus}/> : <img src={plus} alt={plus}/> }
                </button>
                <span className="accordion__title">{title}</span>
            </div>
            
            {expanded && <div className="accordion__text">{text}</div>}
        </div>
    )
}

export default Accordion