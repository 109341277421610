import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import logo from '../../resources/logotype.svg'
import { Auth } from '../Auth'
import { AppState } from '../../reducers'
import { getIsAuthenticated } from '../../selectors/AuthSelectors'
import Popup from './Popup/Popup'

type Props = {
    isAuthenticated: boolean
}

const Header: React.FC<Props> = ({ isAuthenticated }) => {
    const [modalShow, setModalShow] = useState(false)
    const [popupShow, setPopupShow] = useState(false)
    
    return (
        <div className="header">
            <Link to="/" className="inherit">
                <div className="logo">
                    <img src={logo} alt="Логотип"/>
                    <span className="logo__name">R&nbsp;-&nbsp;Forum</span>
                </div>
            </Link>
            
            <div className="auth">
                {
                    isAuthenticated ? (
                        <button className="button button-link"
                                onClick={ () => setPopupShow(true) }>
                            Профиль
                        </button>
                    ) : (
                        <button className="button button-link"
                                onClick={ () => setModalShow(true) }>
                            Войти
                        </button>
                    )
                }
            </div>
            
            { !isAuthenticated ? 
                <Auth 
                    modalShow={ modalShow } 
                    onHide={ () => setModalShow(false) }/>
                : <Popup show={ popupShow } onHide={ () => setPopupShow(false) }/>
            }
        </div>
    )
}

const mapStateToProps = (state: AppState) => {
    const { user: { id, token } } = state
    
    return {
        isAuthenticated: getIsAuthenticated(id, token)
    }
}

export default connect(mapStateToProps)(Header)